import { createRouter, createWebHistory } from "vue-router";
import type { RouteRecordRaw } from "vue-router";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import type { User } from "firebase/auth";

import planningRoutes from "./modules/planning/router";
import timeRegistrationRoutes from "./modules/timeregistration/router";
import vacationRoutes from "./modules/vacation/router";
import { useSystemState } from "./composables/systemState";

const routes: Array<RouteRecordRaw> = [
  ...planningRoutes,
  ...timeRegistrationRoutes,
  ...vacationRoutes,
  {
    path: "/",
    name: "Home",
    components: {
      default: () => import("./pages/page-dashboard.vue"),
      LeftSidebar: () => import("./components/farm-side-bar.vue"),
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/:notificationId",
    name: "HomeNotification",
    components: {
      default: () => import("./pages/page-dashboard.vue"),
      LeftSidebar: () => import("./components/farm-side-bar.vue"),
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/requests",
    name: "Requests",
    components: {
      default: () => import("./pages/page-requests.vue"),
      LeftSidebar: () => import("./components/farm-side-bar.vue"),
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/settings",
    name: "Settings",
    components: {
      default: () => import("./pages/page-farm-settings.vue"),
      LeftSidebar: () => import("./components/farm-side-bar.vue"),
    },
    meta: { requiresAuth: true },
    children: [
      {
        path: "/settings/general",
        name: "SettingsGeneral",
        components: {
          default: () => import("./pages/page-farm-settings-general.vue"),
          LeftSidebar: () => import("./components/farm-side-bar.vue"),
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/settings/employees",
        name: "SettingsEmployees",
        components: {
          default: () => import("./pages/page-farm-settings-employees.vue"),
          LeftSidebar: () => import("./components/farm-side-bar.vue"),
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/settings/units",
        name: "SettingsUnits",
        components: {
          default: () => import("./pages/page-farm-settings-units.vue"),
          LeftSidebar: () => import("./components/farm-side-bar.vue"),
        },
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/user",
    name: "User",
    components: {
      default: () => import("./pages/page-user.vue"),
      LeftSidebar: () => import("./components/user-side-bar.vue"),
    },
    meta: { requiresAuth: true },
    children: [
      {
        path: "/user/profile",
        name: "UserProfile",
        components: {
          default: () => {
            const { isMobile } = useSystemState();
            if (isMobile) {
              return import("./components/user-profile.vue");
            } else {
              return import("./components/empty-placeholder.vue");
            }
          },
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/user/user-management",
        name: "UserManagement",
        components: {
          default: () => import("./components/user-user-management.vue"),
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/user/change-password",
        name: "UserChangePassword",
        components: {
          default: () => import("./components/user-change-password.vue"),
        },
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/auth",
    name: "Auth",
    components: {
      default: () => import("./pages/page-auth.vue"),
    },
    meta: { requiresAuth: false },
    children: [
      {
        path: "/auth/sign-in",
        name: "AuthLogin",
        components: {
          default: () => import("./components/form-sign-in.vue"),
        },
        meta: { requiresAuth: false },
      },
      {
        path: "/auth/reset-password",
        name: "AuthResetPassword",
        components: {
          default: () => import("./components/form-reset-password.vue"),
        },
        meta: { requiresAuth: false },
      },
      {
        path: "/auth/reset-password-complete",
        name: "AuthResetPasswordComplete",
        components: {
          default: () => import("./pages/page-reset-password-complete.vue"),
        },
        meta: { requiresAuth: false },
      },
      {
        path: "/auth/create",
        name: "AuthCreate",
        components: {
          default: () => import("./components/form-create.vue"),
        },
        meta: { requiresAuth: false },
      },
      {
        path: "/auth/create-complete",
        name: "AuthCreateComplete",
        components: {
          default: () => import("./pages/page-create-complete.vue"),
        },
        meta: { requiresAuth: false },
      },
    ],
  },
  {
    path: "/offline",
    name: "Offline",
    components: {
      default: () => import("./pages/page-offline.vue"),
    },
    meta: { requiresAuth: false },
  },
  {
    path: "/guides-beta",
    name: "GuidesBeta",
    components: {
      default: () =>
        import("./modules/guides-beta/components/guides-beta-view.vue"),
      LeftSidebar: () => import("./components/farm-side-bar.vue"),
    },
    meta: {
      requiresAuth: true,
      requiresFarm: true,
    },
  },
  /* To Do {
    path: "/Create",
    name: "Create",
    component: () => import("./pages/page-create.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/ResetPassword",
    name: "ResetPassword",
    component: () => import("./pages/page-reset-password.vue"),
    meta: { requiresAuth: false },
  },*/
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

const getCurrentUser = () => {
  return new Promise<User | null>((resolve, reject) => {
    const removeListener = onAuthStateChanged(
      getAuth(),
      (user) => {
        removeListener();
        resolve(user);
      },
      reject,
    );
  });
};

router.beforeEach(async (to, from, next) => {
  console.log("Routing to:", to.name);

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const user = await getCurrentUser();
    if (user) {
      // If user tries to go to pages that do not require sign in, redirect to home
      if (to.matched.some((record) => !record.meta.requiresAuth)) {
        next("/");
      }
      if (to.path === "/settings") {
        next("/settings/general");
      } else if (to.path === "/user") {
        next("/user/profile");
      } else {
        next();
      }
    } else {
      next("/auth/sign-in");
    }
  } else {
    next();
  }
});

export default router;
